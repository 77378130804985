<template>
  <div>
    <div>
      <v-toolbar flat class="ma-0 pa-0">
        <h3 class="mb-0 font-weight-bolder">Cartons</h3>
        <v-spacer></v-spacer>

        <button
          type="button"
          class="btn btn-info px-5 py-3 ls1"
          @click="showAddCartonModal"
        >
          New Carton
        </button>
      </v-toolbar>
    </div>
    <div class="mx-3 mt-4">
      <v-expansion-panels accordion flat class="">
        <v-expansion-panel
          v-for="(data, index) in $store.getters.getORDERSCreateB2BCartons"
          :key="data.carton.index"
          class="border rounded mb-3"
        >
          <v-expansion-panel-header
            class="font-size-lg font-weight-normal poppins"
          >
            <div class="d-flex pa-0 ma-0 align-center">
              <div>
                <span>{{ index + 1 }}. {{ data.carton.index }} </span>
              </div>
              <v-spacer></v-spacer>
              <v-icon
                color="red"
                size="20"
                class="mr-4"
                @click="() => deleteItem(data.carton.index)"
                >mdi-delete</v-icon
              >
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="ps-3">
            <v-data-table
              :headers="headers"
              :items="data.skus"
              class="elevation-0 ma-0 pa-0"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>SKUs</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <AddCarton
      ref="addCarton"
      :clientId="clientId"
      :warehouse="warehouse"
      :store="store"
    ></AddCarton>
  </div>
</template>

<script>
import AddCarton from "@/own/components/fulfillment/orders/B2B/skuContainerForAdd/AddCarton.vue";
import Swal from "sweetalert2";
import { REMOVE_B2B_CARTONS } from "@/core/services/store/orders.module";
export default {
  name: "SkuDatatable",
  props: ["clientId", "warehouse", "store"],
  components: {
    // EditSku,
    AddCarton,
  },
  data: () => ({
    dialog: false,
    itemForAction: null,
    oldIndex: null,
    // skus: [
    //   {
    //     carton: {
    //       index: "NH37825013",
    //       text: "NH378250123",
    //     },
    //     skus: [
    //       {
    //         skuId: 193772,
    //         systemCode: "5200887041911",
    //         quantity: 1,
    //         price: "179.00",
    //         store_code: null,
    //         description: "SKIRT MIDI",
    //         goods_price: 0,
    //       },
    //       {
    //         skuId: 193772,
    //         systemCode: "5200887041911",
    //         quantity: 1,
    //         price: "179.00",
    //         store_code: null,
    //         description: "SKIRT MIDI",
    //         goods_price: 0,
    //       },
    //       {
    //         skuId: 193772,
    //         systemCode: "5200887041911",
    //         quantity: 1,
    //         price: "179.00",
    //         store_code: null,
    //         description: "SKIRT MIDI",
    //         goods_price: 0,
    //       },
    //       {
    //         skuId: 193772,
    //         systemCode: "5200887041911",
    //         quantity: 1,
    //         price: "179.00",
    //         store_code: null,
    //         description: "SKIRT MIDI",
    //         goods_price: 0,
    //       },
    //     ],
    //   },
    // ],
  }),
  computed: {
    headers() {
      return [
        { text: "SKU ID", value: "skuId" },
        { text: "SKU Barcode", value: "systemCode" },
        { text: "Quantity", value: "quantity" },
        { text: "Description", value: "description" },
      ];
    },
  },
  methods: {
    showAddCartonModal() {
      if (this.warehouse && this.store) {
        this.$nextTick(() => {
          this.$refs.addCarton.toggleModal();
        });
      } else {
        Swal.fire({
          title: "Warning",
          text: `Warehouse and store fields are required!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    deleteItem(index) {
      const foundIndex =
        this.$store.getters.getORDERSCreateB2BCartons.findIndex(
          (item) => item.carton.index === index
        );

      this.$store.commit(REMOVE_B2B_CARTONS, foundIndex);
    },
  },
};
</script>

<style scoped>
.border {
  border: 1px solid #ebedf3 !important;
}
</style>
